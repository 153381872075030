*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  position: relative ;
}

html, body{
  min-height: 100vh;
}

body {
  @include content(max);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img{
  max-width: 100%;
  height: auto;
  line-height: normal;
}

::selection {
  background-color: $colorPrimary;
  color: $colorWhite;
}
