@font-face {
    font-family: 'PPNeueMontreal';
    src: url("../../fonts/pp-neue-montreal/PPNeueMontreal-Book.woff2") format("woff2"), url("../../fonts/pp-neue-montreal/PPNeueMontreal-Book.woff") format("woff"), url("../../fonts/pp-neue-montreal/PPNeueMontreal-Book.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PPNeueMontreal';
    src: url("../../fonts/pp-neue-montreal/PPNeueMontreal-Medium.woff2") format("woff2"), url("../../fonts/pp-neue-montreal/PPNeueMontreal-Medium.woff") format("woff"), url("../../fonts/pp-neue-montreal/PPNeueMontreal-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

body {
    font-weight: 400;
    font-size: $fontSizeDefault;
    line-height: $lineHeightDefault;
    letter-spacing: 1.5px;
    font-family: 'PPNeueMontreal', sans-serif;

    h1,
    h2,
    h3 {
        font-weight: 400;
        font-size: 4.5rem;
        line-height: 6rem;
        letter-spacing: 1px;
        word-break: break-word;
        hyphens: auto;
        color: $colorPrimary;
    }

    h4,
    h5,
    h6 {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 3rem;
        letter-spacing: 4px;
        text-transform: uppercase;
        word-break: break-word;
        hyphens: auto;
        color: $colorPrimary;
    }

    a {
        color: $colorPrimary;
        text-decoration: none;
    }

    b,
    strong {
        font-weight: 500;
    }

    ol,
    ul {
        margin: 0;
        padding: 0 0 0 0;
        list-style: none;
    }

    p {
        margin-bottom: .8rem;
    }

    small,
    .small {
        font-size: $fontSizeSmall;
        line-height:$lineHeightSmall;
    }

    form label,
    form .label {
        font-size: $fontSizeInput;
        line-height: $lineHeightDefault;
        font-weight: 500;
        letter-spacing: 4px;
        text-transform: uppercase;
    }

    note,
    .note {
        font-weight: 400;
        font-size: $fontSizeDefault;
        line-height: $lineHeightDefault;
        letter-spacing: 1.5px;
    }

    input,
    select,
    textarea {
        /*font-size: $fontSizeInput;
        line-height: $lineHeightDefault;
        font-weight: 500;
        letter-spacing: 4px;
        text-transform: uppercase;*/

        font-weight: 400;
        font-size: $fontSizeInput;
        line-height: $lineHeightDefault;
        letter-spacing: 1.5px;
        color:$colorPrimary;

        &:-moz-placeholder {
            font-size: $fontSizeInput;
            line-height: $lineHeightDefault;
            color:$colorPrimary;
            font-weight: 500;
            letter-spacing: 4px;
            text-transform: uppercase;
        }
        &::-moz-placeholder {
            font-size: $fontSizeInput;
            line-height: $lineHeightDefault;
            color:$colorPrimary;
            font-weight: 500;
            letter-spacing: 4px;
            text-transform: uppercase;
        }
        &:-ms-input-placeholder {
            font-size: $fontSizeInput;
            line-height: $lineHeightDefault;
            color:$colorPrimary;
            font-weight: 500;
            letter-spacing: 4px;
            text-transform: uppercase;
        }
        &::-webkit-input-placeholder {
            font-size: $fontSizeInput;
            line-height: $lineHeightDefault;
            color:$colorPrimary;
            font-weight: 500;
            letter-spacing: 4px;
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: $smallDesktop) {
  body {
    font-size: 1.6rem;
    line-height: 2.4rem;

    note,
    .note {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    h1,
    h2,
    h3 {
      font-size: 3rem;
      line-height: 4.2rem;
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    font-size: 1.4rem;
    line-height: 2rem;

    note,
    .note {
      font-size: 1.4rem;
      line-height: 2.0rem;
    }

    small,
    .small {
      font-size: 0.9rem;
      line-height:1.5rem;
    }

    form label,
    form .label {
      font-size: 1.1rem;
      line-height: 2.4rem;
    }

    h1,
    h2 {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    h3 {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }

    h4,
    h5,
    h6 {
      font-size: 1.1rem;
      line-height: 2.4rem;
    }
  }
}